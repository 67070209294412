<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              评分类型：
              <el-select
                v-model="parameter.scoreType"
                class="ipt_width"
                placeholder="请选择评分类型"
                clearable
              >
                <el-option
                  v-for="item in dictData.ScoreTypes"
                  :key="item.id"
                  :label="item.dictName"
                  :value="item.dictVal"
                />
              </el-select>
            </div>
            <div class="search_center">
              员工：
              <el-select
                v-model="parameter.userId"
                class="ipt_width"
                filterable
                placeholder="请选择员工"
                clearable
              >
                <el-option
                  v-for="item in staffList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="search_center">
              商务名称：
              <el-select
                v-model="parameter.businessId"
                clearable
                filterable
                placeholder="请选择商务名称"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.id"
                  :label="item.businessName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search_center">
              项目名称：
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请选择项目名称"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 考核月份 -->
            <div class="search_center">
              考核月份：
              <el-date-picker
                v-model="parameter.examineMonth"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择日期"
                class="ipt_width"
                clearable
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              评分人：
              <!-- <el-input
                v-model="parameter.raterName"
                class="ipt_width"
                placeholder="请输入评分人"
                clearable
              ></el-input> -->
              <el-select
                v-model="parameter.raterId"
                class="ipt_width"
                filterable
                placeholder="请选择员工"
                clearable
              >
                <el-option
                  v-for="item in staffList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                />
              </el-select>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button
            type="primary"
            icon="el-icon-printer"
            :loading="exportingLoading"
            @click="exportingFn"
            >导出</el-button
          >
          <!-- <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
            >新增</el-button
          > -->
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              align="center"
              prop="userName"
              label="员工姓名"
              width="120"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="deptName"
              label="部门"
              width="180"
            ></el-table-column>
            <el-table-column align="center" prop="scoreType" label="评分类型">
              <template slot-scope="scope">
                <span>{{ scope.row.scoreType | dict(dictData.ScoreTypes) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="auditHour" label="工时"></el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              label="业务名称"
              width="180"
            ></el-table-column>
            <el-table-column align="center" prop="examineMonth" label="考核月份">
              <template slot-scope="scope">
                <span>{{ scope.row.examineMonth }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="scoreRatio" label="评分系数">
              <template slot-scope="scope">
                <span>{{ scope.row.scoreRatio | formatScoreRatio }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="raterName" label="评分人">
              <template slot-scope="scope">
                <span>{{ scope.row.raterName }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="scoreDate" label="考核评分日期" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.scoreDate | formatDate('yyyy-MM-dd') }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="isScore"
              label="是否评分"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.isScore ? "已评分" : "未评分" }}</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                  v-if="scope.row.operatingButton.includes('VIEW')"
                >
                  详情
                </el-button>
                <el-button
                  type="warning"
                  size="mini"
                  class="text_Edit_Bgc"
                  @click.stop="handleEdit(scope.row)"
                  v-if="scope.row.operatingButton.includes('EDIT')"
                >
                  编辑
                </el-button>
                <el-button
                  type="text"
                  size="mini"
                  class="text_Remove_Bgc"
                  @click.stop="handleDelete(scope.row)"
                  v-if="scope.row.operatingButton.includes('DELETE')"
                >
                  删除
                </el-button>
              </template>
            </el-table-column> -->
          </el-table>
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            :total="parameter.total"
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit
      :isShow.sync="editShow"
      :options="options"
      @closeEdit="closeEdit"
      :ScoreTypes="dictData.ScoreTypes"
      :staffList="staffList"
    ></Edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../scoreEdit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        order: '',
        orderRule: '',
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      tableData: [], // 表格数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      deptList: [], // 部门 列表
      options: {},
      dictData: {},
      staffList: [],
      businessList: [],
      projectList: [],
      exportingLoading: false,
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toFixed(2)
      } else {
        return n
      }
    },
    formatDate(date, format) {
      if (date) {
        let dateObj = new Date(date)
        let year = dateObj.getFullYear()
        let month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
        let day = dateObj.getDate().toString().padStart(2, '0')

        return format.replace('yyyy', year).replace('MM', month).replace('dd', day)
      }
      return date
    },
    formatScoreRatio(n) {
      if (n) {
        return Number(n).toFixed(1)
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getDictData()
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.getTableData() /** 加载 "发票申请" 数据 **/
  },
  watch: {},
  methods: {
    exportingFn() {
      this.exportingLoading = true
      this.$api.compensationManage
        .exporting(this.parameter)
        .then(res => {
          const fileName = '员工综合评分.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
          this.exportingLoading = false
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {
          this.exportingLoading = false
          console.log(e)
        })
    },

    selectMonth() {},
    staffQuerySearch(queryString, cb) {
      var staffs = this.staffList
      var results = queryString ? staffs.filter(this.createStaffFilter(queryString)) : staffs
      // 转换数据格式
      results = results.map(item => ({
        value: item.userName,
        id: item.id,
        ...item,
      }))
      cb(results)
    },
    createStaffFilter(queryString) {
      return staff => {
        return staff.userName.indexOf(queryString) === 0 || staff.id.indexOf(queryString) === 0
      }
    },
    closeEdit(isRefresh = false) {
      this.editShow = false
      if (isRefresh === true) {
        this.getTableData() /** 加载 "发票申请" 数据 **/
      }
    },
    handleAdd() {
      this.options = {}
      this.options.title = '新增评分'
      this.editShow = true
    },
    /** 加载 "字典" 数据 **/
    getDictData() {
      // SCORE_TYPE: 评分类型
      this.$api.dict
        .listSysDictData('SCORE_TYPE', true)
        .then(res => {
          this.dictData.ScoreTypes = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .getProjectInfo()
        .then(res => {
          if (!res.data) {
            this.projectList = []
          } else {
            this.projectList = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.businessManage
        .getBusinessList()
        .then(res => {
          if (!res.data) {
            this.businessList = []
          } else {
            this.businessList = res.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getDeptList() {
      this.$api.user
        .staffList()
        .then(res => {
          this.staffList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      ;(this.parameter = {
        order: '',
        orderRule: '',
        pageNow: 1,
        pageSize: this.pageSize,
        total: 0,
      }),
        this.getTableData() /** 加载 "年浮动工资" 数据 **/
    },
    getTableData() {
      this.loading = true
      this.$api.compensationManage
        .staffScoreList(this.parameter)
        .then(res => {
          this.tableData = res.data?.records
          this.parameter.total = res.data?.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.options = item
      this.options.mode = 'view'
      this.options.title = '评分详情'
      this.editShow = true
    },
    handleEdit(item) {
      if (!item?.isAuditCompleted) {
        this.$message.warning('请先完成全部工时审核')
        return
      }
      this.options = item
      this.options.mode = 'edit'
      this.options.title = '编辑评分'
      this.editShow = true
    },
    handleDelete(item) {
      this.$confirm('请确认是否删除当前评分？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.loading = true
        this.$api.compensationManage
          .deleteScoreRatio(item.id)
          .then(res => {
            this.$message.success('删除成功')
            this.getTableData()
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page
      this.getTableData() /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup(isRefresh = false) {
      this.editShow = false
      if (isRefresh === true) {
        this.getTableData() /** 加载 "发票申请" 数据 **/
      }
    },
  },
}
</script>

<style lang="scss" scoped>
//   /deep/ .el-autocomplete-suggestion {
//   width: auto !important;
// }
</style>
